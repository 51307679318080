import { VrcExperience } from '~/types/plan';

export type CartConfigAddressCheck = {
  title?: {
    copy?: string;
  };
  subtitle?: {
    copy?: string;
    enabled?: string;
    icon?: string;
  };
  headingLevel?: string;
  placeholder?: string;
  verifyPhoneEnabled?: string; // TODO change this into a string
  vrcExperience?: VrcExperience;
  resumeModal?: {
    heading?: {
      copy?: string;
    };
    subheading?: {
      copy?: string;
    };
    button?: {
      copy?: string;
    };
    tooltip?: {
      copy?: string;
    };
  };
};

export const cartConfigAddressCheckDefaultValue: CartConfigAddressCheck = {
  title: {
    copy: 'Enter your address to view plans and exclusive offers.',
  },
  subtitle: {
    copy: 'You may be eligible for special offers, like expert installation and a Wi-Fi router included in your plan.',
    enabled: 'true',
    icon: 'star',
  },
  headingLevel: '1',
  placeholder: 'Enter your address',
  verifyPhoneEnabled: 'false',
  resumeModal: {
    heading: {
      copy: "Welcome back. Let's finish your order.",
    },
    subheading: {
      copy: "Welcome back. Let's finish your order.",
    },
    button: {
      copy: 'CONTINUE CHECKOUT',
    },
    tooltip: undefined,
  },
  vrcExperience: {
    vrcPassbackEnabled: false,
    vrcExperienceModalEnabled: false,
    vrcExperienceExitIntentEnabled: false,
    vrcExperienceModalDelay: 30000,
    vrcExperienceModalHeadline: "Don't miss this exclusive online only offer!",
    vrcExperienceModalSubheadline: 'Order Fiber 1 Gig today, get a $100 Visa® Reward Card. Online only.',
    vrcExperienceModalTooltip:
      'You will receive an email including the $100 Visa® Reward Card details within 24 hours of your online submitted order. One-year agreement, other terms and redemption requirements apply.',
    vrcExperienceModalCta: 'Get 1 gig',
    vrcExperienceYttv: {
      enabled: false,
      showAllPromoBanner: true,
      value: 50,
    },
    vrcExperienceReturnVisitorLtoEnabled: false,
    vrcExperiencePlanSpeeds: {
      '200': false,
      '500': false,
      '1000': false,
      '2000': false,
      '5000': false,
      '7000': false,
    },
  },
};
