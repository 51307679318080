import React, { FC, useEffect, useState } from 'react';
import Head from 'next/head';
import PageHeader from '~/components/PageHeader';
import { Star, StopwatchRed } from '~/components/Icons/outline';
import AddressCheckStyled from '~/styles/pages/AddressCheckStyled';
import OneColumn from '~/components/Layout/OneColumn';
import useAddressCheck from '~/hooks/useAddressCheck';
import { useDispatch, useSelector } from 'react-redux';
import { removeQuote, setCallInNumber } from '~/redux/actions/common';
import { setAmexOffer } from '~/redux/actions/global';
import { DEFAULT_SUBTITLE } from '~/constants/address';
import AddressForm from '~/components/AddressForm';
import Modal from '~/components/Modal';
import ResumeModal from '~/components/Modal/screens/Resume';
import { ModalKeys, Modals } from '~/types/products';
import Cookies from 'js-cookie';
import clientSide from '~/helpers/clientSide';
import { isBeforeDate } from '~/helpers/isBeforeDate';
import { ReduxState } from '~/types/redux';
import { trackEvent } from '~/helpers/tracking';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';
import { MonarchSlot, useMonarchSlot } from '@redventures/cohesion-utils-react';
import { cartConfigAddressCheckDefaultValue } from '../../monarch/Config | Address Check/default';
import { clearServiceabilityResults } from '~/redux/actions/address';
import Cookie from 'js-cookie';

const iconMap = {
  star: Star,
  stopwatch: StopwatchRed,
};

const AddressCheck: FC = (): JSX.Element => {
  const [activeModal, setActiveModal] = useState<Modals>();
  const [hasRemovedCookie, setHasRemovedCookie] = useState(false);
  const {
    resume,
    monarch,
    landingPage,
    cookiePass,
    quiz,
    tracking: { quoteId },
  } = useSelector((store: ReduxState) => store);
  const { determineResumeQuote, cartQuoteCutoffDate } = monarch;
  const { apiVersion, selectedAddress, setSelectedAddress, handleFormSubmit, handleInputChange, buttonState } =
    useAddressCheck();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (clientSide && determineResumeQuote === true && !quiz?.quizStarted) {
      const quoteCreated = Cookies.get('quoteCreated');
      const dateIsBefore = isBeforeDate(quoteCreated, cartQuoteCutoffDate);
      const declinedResume =
        window?.sessionStorage?.getItem('declinedResume') ||
        resume?.acceptedResume === false ||
        landingPage ||
        cookiePass;
      const completedResume = resume?.completed === true;
      if (Cookies.get('quoteId') && quoteId && !declinedResume && !completedResume && dateIsBefore) {
        // For tracking purposes, we need to set a flag to indicate that the resume modal is active.
        // This is used to prevent the cart flow from being updated while the modal is open.
        window.sessionStorage.setItem('resumeModalActive', 'true');
        setActiveModal(ModalKeys.RESUME);
        trackEvent({
          action: 'elementViewed',
          data: {
            elementType: 'MODAL',
            location: 'ADDRESS CHECK',
            position: 'Resume Modal',
            text: 'Resume Modal ',
          },
        });
      }
      if (declinedResume) {
        setNewRelicCustomAttribute([{ name: 'declinedResume', value: 'true' }]);
      }
      // only delete and reset quoteId the first time the user visits the address check page after the quote cutoff date
      if (!dateIsBefore && Cookies.get('quoteId') && !hasRemovedCookie) {
        setNewRelicCustomAttribute([{ name: 'cartQuoteCutoffDate', value: Cookies.get('quoteId') }]);
        dispatch(removeQuote());
        setHasRemovedCookie(true);
        window.sessionStorage.setItem('declinedResume', 'true');
      }
    }
  }, [determineResumeQuote]);

  useEffect(() => {
    if (!Cookie.get('frontierSiteDetailPredictive')) {
      dispatch(clearServiceabilityResults());
    }

    const isAmexOffer = window?.location?.href?.toLowerCase().includes('amex');
    if (isAmexOffer) {
      Cookies.set('isAmexOffer', true);
      dispatch(setCallInNumber('877-837-6222'));
      dispatch(setAmexOffer(true));
    } else {
      Cookies.set('isAmexOffer', false);
    }
  }, []);

  // Wait to show address check form until we've ensured a user is not in the cookie pass/Allconnect flow
  useEffect(() => {
    if (!cookiePass) {
      setShowForm(true);
    }

    // Fallback after 2 seconds so that a user never sits on /buy unable to submit an address
    setTimeout(() => {
      setShowForm(true);
    }, 2000);
  }, [cookiePass]);

  // Grabbing Address Check Page Config slot's metadata
  const metadata = useMonarchSlot('addressCheck', {
    default: cartConfigAddressCheckDefaultValue,
  })?.data;

  const getTitle = (copy: string) => {
    // Show specific copy when product is selected on landing page
    if (clientSide && window?.sessionStorage?.getItem('selectedProductId')) {
      return 'Enter your address to continue checkout and view exclusive offers';
    }
    return copy ?? 'Enter your address to view plans and exclusive offers.';
  };

  const getSubtitle = (enabled: boolean, copy: string) => {
    // Show specific copy when product is selected on landing page
    if (clientSide && window?.sessionStorage?.getItem('selectedProductId')) {
      return 'You may be eligible for special offers, like expert installation and a Wi-Fi router included in your plan.';
    }
    return enabled !== false ? copy || DEFAULT_SUBTITLE : '';
  };

  const Icon = iconMap[metadata?.subtitle?.icon] || Star;

  return (
    <OneColumn>
      <Head>
        <title>Address check</title>
      </Head>
      <AddressCheckStyled showForm={showForm}>
        <MonarchSlot slotId="addressCheck">
          <>
            <PageHeader
              title={getTitle(metadata?.title?.copy)}
              subtitle={{
                icon: <Icon />,
                text: getSubtitle(metadata?.subtitle?.enabled === 'true', metadata?.subtitle?.copy),
              }}
              headingLevel={metadata?.headingLevel === '2' ? 2 : 1} // TODO Fix the type in Monarch
            />
            <AddressForm
              apiVersion={apiVersion}
              onSubmit={handleFormSubmit}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              handleInputChange={handleInputChange}
              buttonState={buttonState}
              placeholder="Enter your address"
              // TODO Fix the type in Monarch
              title={metadata?.title?.copy || ''}
              {...(metadata && { ...metadata, title: undefined })}
            />
          </>

          <Modal zIndex={9998} visible={Boolean(activeModal)}>
            <ResumeModal
              setActiveModal={setActiveModal}
              visible={activeModal === ModalKeys.RESUME}
              apiVersion={apiVersion}
            />
          </Modal>
        </MonarchSlot>
      </AddressCheckStyled>
    </OneColumn>
  );
};

AddressCheck.displayName = 'AddressCheck';

export default AddressCheck;
