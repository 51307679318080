import styled, { css } from 'styled-components';
import convertHexToRgba from '~/helpers/convertHexToRgba';

export const ResumeModalStyled = styled.div.attrs({
  className: 'resume-screen',
})`
  ${({ theme }) => {
    const { colors, media } = theme;
    return css`
      background-color: ${colors.primary.white};
      box-shadow: 0 20px 40px 0 ${convertHexToRgba(colors.primary.black, 0.25)};
      width: 1110px;
      height: 380px;
      line-height: 34px;
      display: flex;
      flex-direction: column;
      padding: 0px 0px 20px;
      border-radius: 32px;
      position: relative;
      text-align: center;
      justify-content: center;

      ${media.tablet.only} {
        width: 720px;
      }

      ${media.mobile.down} {
        width: 310px;
        padding: 4% 2.5%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .icon--fill-close-thin {
        fill: ${colors.secondary.gray};
        height: 16px;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        cursor: pointer;
      }

      .icon--fill-alert {
        width: 64px;
        height: 64px;
        margin: 20px;
      }

      .resume {
        &__header {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 18px;
          ${media.mobile.down} {
            font-size: 16px;
          }
        }

        &__cancel {
          color: ${colors.primary.black};
          background-color: ${colors.primary.whiteSmoke};
          border-color: ${colors.primary.black};
        }
        &__container {
          line-height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: ${theme.colors.primary.darkBlue};

          edit-address {
            display: none;
          }
        }

        &__title {
          width: 100%;
          font-size: 34px;
          font-weight: 700;

          ${media.mobile.down} {
            font-size: 20px;
          }
        }

        &__button-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          padding: 0;

          button {
            font-size: 18px;
            max-height: 46px;
            padding: 12px, 24px, 12px, 24px;
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            ${media.mobile.down} {
              max-width: 343px;
            }
          }
        }
      }
    `;
  }}
`;
